$first-color: #3085D6;
$second-color: #0069D9;
$third-color: #0062CC;

.btn-primary {
    color: #FFF;
    background-color: $first-color;
    border-color: $first-color;
}

.btn-outline-primary {
    color: $first-color;
    border-color: $first-color;
}

.btn-primary:hover,
.btn-outline-primary:hover {
    color: #FFF;
    background-color: $second-color;
    border-color: $third-color;
}

.btn-primary:focus,
.btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5);
            box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #FFF;
    background-color: $first-color;
    border-color: $first-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #FFF;
    background-color: $third-color;
    border-color: #005CBF;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5);
            box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5);
}

.badge-primary {
    color: #FFF;
    background-color: $first-color;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #FFF;
    background-color: $third-color;
}

.alert-primary {
    color: #004085;
    background-color: #CCE5FF;
    border-color: #B8DAFF;
}

.alert-primary hr {
    border-top-color: #9FCDFF;
}

.alert-primary .alert-link {
    color: #002752;
}

.list-group-item-primary {
    color: #004085;
    background-color: #B8DAFF;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9FCDFF;
}

.list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #004085;
    border-color: #004085;
}

.bg-primary {
    background-color: $first-color !important;
}

a.bg-primary:hover
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: $third-color !important;
}

.text-primary {
    color: $first-color !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0056B3 !important;
}

.notify-alert {
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
    border: 0 !important;
    max-width: 400px;
    color: #FFF;
}

.notify-alert.alert-success {
    background-color: #28a745;
}

.notify-alert.alert-info {
    background-color: #17a2b8;
}

.notify-alert.alert-warning {
    background-color: #ffce3a;
}

.notify-alert.alert-danger {
    background-color: #e04b59;
}

.notify-alert button[data-notify="dismiss"] {
    margin-left: 5px;
    outline: none !important;
}

.widget-small.primary {
    background-color: $first-color;
}

.widget-small.primary.coloured-icon .icon {
    background-color: $first-color;
    color: #fff;
}

.app-header__logo:hover {
    
}