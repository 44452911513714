.app-title {
	h1 {
		line-height: 1.2;
	}
}

.btn-plus {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	position: fixed;
	right: 2vh;
	bottom: 2vh;
	z-index: 9;
	padding: 0;
}

.btn-table {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
	.animated-checkbox.checkbox-xl-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}
}

@media (max-width: 576px) {
	.animated-checkbox.checkbox-xs-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}

	.img-size-sm { height: 20vh; }
}

form { margin-block-end: 0; }

.form-group.required label:after {
	content:"*";
	color:red;
	font-weight: bold;
}

.btn-widget {
	text-decoration: none !important;
	transition: 0.2s ease;

    &:hover {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    }
}

.checked {
	position: absolute;
	right: 0;
	top:-3px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, .3);
}

.checked-one { right: 0; }

.filter-footer {
	width: 100%;
	bottom: 0;
	position: absolute;
}

.slider-selection { background: #81A1C3 !important; }

.unchecked { color: #FF0000 !important; }

.tooltip.in { opacity: 1; }

table.dataTable>tbody>tr.child ul.dtr-details { display: block !important; }

.list-group-item.active { background-color: #17A2B8; }

.disabled, .disabled div {
	opacity: 0.7;
	cursor: not-allowed;
}

.badge-pill {
	width: 40%;
	height: 35%;
	padding: 0;
	padding-top: 4px;
	margin-top: -3px;
}

.animated-checkbox.checkbox-lg input[type="checkbox"] + .label-text:before {
	font-size: 40px;
}

.bg-overlay {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1051;
	visibility: hidden;
	opacity: 0.0;
	overflow: hidden;
	text-align: center;
	transition: 0.3s ease;

    img {
        position: fixed;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

	.loading-text {
		position: fixed;
		margin: 0;
		top: 60%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #a1acb6;
	}
}

.bg-overlay-show {
	visibility: visible;
	opacity: 1.0;
}

.datetimepicker {
	z-index: 99999999;
}

.app-nav__item { line-height: 0; }

.g-recaptcha {
	display: inline-block;
}

.table-orange {
	background-color: rgba(253,126,20, 0.5);
}

.unclickable {
	pointer-events: none;
}

.thumb {
	width: auto;
	height: 170px;
	max-height: 170px;
	transition: all .5 ease;
	transform-origin: center;

	&.thumb-sm {
		height: 120px;
	}

	&.thumb-show {
		position: absolute;
		top: -60px;
	}
}

.modal-dialog {
	&.with-thumb {
		top: 60px;
		margin-bottom: 70px;
		height: auto;
		min-height: calc(100vh - 80px);
		margin: 0 auto;
		padding: 10px;
	}
}

// table.dataTable {
// 	width: 100% !important;
// }

.form-group {
	> label {
		width: max-content;
		padding-top: 8px;
		padding-bottom: 10px;
	}
}

.badge {
	line-height: inherit;
	min-width: 100px;
	box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}

.input-group-text {
	border: 1px solid rgba(0,0,0,0.2);
}

.btn-help {
	position: absolute;
	top: -5px;
	margin-left: 5px;
}

.btn-card {
	height: 70px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-shadow: inset 0px -1px 2px rgba(0,0,0,0.1);
	border-radius: 8px;
	border: 1px solid rgba(0,0,0,0.1);

	&:hover {
		box-shadow: 0 1px 3px rgba(0,0,0,0.3);
	}

	&:active {
		position: relative;
		top: 2px;
		transform: scale(0.99);
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.jumbotron-theme {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;

	@media (min-width: 576px) {
		padding: 4rem 2rem;
	}
}

.cursor-pointer { cursor: pointer; }
.breadcrumb-item { align-items: center; }