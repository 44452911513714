.image-container {
	display: flex;
	align-items: flex-start;

	.btn-image-trash {
		position: relative;
		top: -15px;
		right: -15px;
	}
	
	img {
		cursor: pointer;
		margin-right: -35px;
	}
}