%button-circle {
	width: 45px;
	height: 45px;
	border-radius: 50px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .fa {
        margin: 0;
    }

    span {
        margin-left: 5px;
    }
}

.btn-circle {
	@extend %button-circle;	

    &.btn-circle-xsm {
        width: 22px;
        height: 22px;
        font-size: 0.9rem;
    }

    &.btn-circle-sm {
        width: 35px;
        height: 35px;
        font-size: 0.9rem;
    }

    &.btn-circle-lg {
        width: 55px;
        height: 55px;
        font-size: 1.1rem;
    }

    &.btn-circle-xl {
        width: 70px;
        height: 70px;
        font-size: 1.3rem;
    }

    &.btn-circle-title-xs {
        width: auto;
        border-radius: 50px;
    }

    @media (min-width: 540px) {
        &.btn-circle-title-sm {
            width: auto;
            border-radius: 50px;
        }
    }

    @media (min-width: 992px) {
        &.btn-circle-title {
            width: auto;
            border-radius: 50px;
        }
    }
}