.wizard {
    font-size: 16px;

	.disabled { pointer-events: none; }

	.modal-dialog {
		.modal-content {
			border-radius: 17px 16px 16px 17px;

			.modal-body {
				nav {
					background-color: #304767;
					color: #FFF;
					border-radius: 16px 0 0 16px;
					position: initial;

					&.nav-browse {
						transition: all 0.2s ease;

						.header-mobile {
							h3 {
								margin: auto;
    							display: none;
							}
						}

						&.active {
							display: block !important;
							border-radius: 0;
							position: absolute;
							z-index: 5;
							height: 100%;

							h3 {
								display: none;
							}
						}
					}

					&.nav-mobile {
						border-radius: 0;
						height: 50px;
						padding: 0;

						&.inactive {
							display: none;
						}
					}

					.progress-buttons {
						list-style: none;
						margin-top: 30px;
						font-size: 16px;
						font-weight: 600;
						counter-reset: container 0;
						padding-left: 20px;

						.step {
							position: relative;
							margin-left: 50px;
							margin-top: 40px;
							counter-increment: container 1;
							color: #4f6581;
							cursor: pointer;
							transition: all 0.2s ease;

							&:first-child::after {
								display: none;
							}

							&:nth-child(6)::after {
								height: 500%;
								top: -500%;
								z-index: 0;
							}

							&::before {
								content: counter(container);
								display: flex;
								justify-content: center;
								align-items: center;
								line-height: 0;
								text-align: center;
								position: absolute;
								width: 32px;
								height: 32px;
								border: 2px solid #4f6581;
								border-radius: 50%;
								background-color: #304767;
								left: -50px;
								top: -5px;
								z-index: 1;
								transition: all 0.2s ease;
							}
							
							&::after {
								content: '';
								position: absolute;
								width: 2px;
								height: 250%;
								background-color: #4f6581;
								z-index: 0;
								left: -35px;
								top: -250%;
								transition: all 0.2s ease;
							}

							&.active {
								color: #FFF;

								&::before {
									color: #FFF;
    								border: 2px solid #FFF;
								}

								&::after {
									background-color: #FFF;
								}
							}
						}

						&.horizontal {
							margin-top: 0;
							padding: 0;
							justify-content: center;
							align-items: center;
							width: 100%;

							.step {
								margin-left: 4%;
    							margin-top: 0;

								&:nth-child(1) {
									margin-left: 0;
								}

								&:nth-child(6):after {
									width: 100%;
									height: 2px;
									top: 15px;
								}

								&::before {
									width: 44px;
									height: 44px;
									font-size: 22px;
									position: sticky;
								}

								&::after {
									width: 100%;
									height: 2px;
									left: -100%;
									top: 15px;
								}
							}
						}
					}

					.bt-arrow {
						color: #FFF;
						font-size: 20px;
						padding: 5px 10px;
						height: 50px;
						display: none;
					}
				}

				.tab-content {
					.tab-pane {
						&.show {
							display: flex;
							place-content: stretch space-around;
							flex-direction: column;
							align-items: stretch;
							height: 100%;
							min-height: 530px;
						}

						&.max-h {
							max-height: 530px;
						}

						.buttons {
							width: 100%;
							display: flex;
							flex-direction: row;
							justify-content: center;

							.button {
								background-color: #009688;
								border-radius: 50%;
								color: #FFF;
								width: 90px;
								height: 90px;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 60px;
								text-decoration: none;
								transition: all 0.2s ease;
								margin: 5px;

								&.prev {
									background-color: #EBEBEB;
								}

								&.disabled {
									background-color: #7CB7B1;
								}

								&.active {
									transform: scale(0.9);
								}
							}
						}

						.container-classrooms {
							max-height: 40%;
							height: 100%;
							overflow-y: auto;
						}

						select, input {
							font-family: 'FontAwesome', Arial !important;
							font-size: 20px;
							font-weight: 600;
							height: 60px;
							border: 0;
							border-bottom: 2px solid #EEE;
							border-radius: 0;

							&:focus {
								outline: none;
    							box-shadow: none;
							}
						}

						label, .label-text {
							width: 100%;
							cursor: pointer;
						}

						.form-control {
							&::placeholder {
								color: #AAA;
								padding-left: 2px;
							}
						}

						.animated-checkbox, .animated-radio-button {
							border: 1px solid rgba(0, 0, 0, 0.2);
							border-radius: 6px;
							padding: 5px;
							margin: 2px;
						}

						.header-mark {
							text-align: right;
							position: absolute;
							top: 10px;
							right: 16px;
						}

						.bt-confirm {
							padding: 16px 50px;
    						font-weight: 600;
						}

						.table {
							text-align: center;

							.list-group-item {
								padding: 0;

								a {
									border: none;
									font-weight: 600;

									&:hover {
										text-decoration: none;
										background-color: rgba(0, 0, 0, 0.1);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.arrow-collapse {
		float: right;
	}

	a:not(.collapsed) {
		.arrow-collapse {
			.rotate-arrow {
				transform: rotate(180deg);
			}
		}
	}

	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		background: #F1F1F1;
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb:hover { background: #555; }

	@media (max-width: 1200px) {
		.modal-dialog {
			max-width: 96%;
		}
	}

	@media (max-width: 768px) {
		padding: 0 !important;
	
		.modal-dialog {
			margin: 0;
			max-width: 100%;

			.modal-content {
				border-radius: 0;
				min-height: 100vh;
				height: auto;

				.modal-body {
					nav {
						.progress-buttons {
							&.horizontal {
								.step {
									&::before {
										width: 32px;
										height: 32px;
										font-size: 18px;
									}
								}
							}
						}

						.bt-arrow {
							display: block;
						}
					}

					.tab-content {
						.buttons {
							.button {
								width: 64px;
        						height: 64px;
							}
						}

						.form-control {
							&::placeholder {
								font-size: 14px;
							}
						}

						.header-mark {
							top: 5px;
						}
					}
				}
			}
		}
	}
}