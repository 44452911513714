@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

@import 'vali-theme-custom';
@import 'swal-custom';
@import 'animations/pulse';
@import 'default';
@import 'search';
@import 'field-list-custom';
@import 'progress-bar-custom';
@import 'bootstrap-select-custom';
@import 'footer';
@import 'login';
@import 'wizard';

@import 'utils/btn-circle';
@import 'card-custom';
@import 'image-manager';