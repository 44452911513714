.footer {
	height: 56px;
	background-color: #F8F9FA;
	font-family: Arial;
	transition: all 0.3s;

    .bt {
        position: relative;
	    top:-5px;
    }
}